import React from 'react';
import HomePage from './HomePage';
import Mcq from './Mcq';
import Tutorials from './Tutorials';
import Header from './Components/Header';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Footer from './Components/Footer';
import BlogPost from './Components/BlogPost';
import UnderDevelopment from './UnderDevelopment';

function App() {
  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/mcq' element={<Mcq />} />
          <Route path='/tutorial' element={<Tutorials />} />
          <Route path='/underdev' element={<UnderDevelopment />} />
          <Route path='/:slug' element={<BlogPost />} /> 
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
