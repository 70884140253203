import React from 'react';
import { Twitter, Linkedin } from 'lucide-react';

const Footer = () => (
  <footer className="bg-gradient-to-r from-indigo-800 to-purple-800 text-white py-8">
    <div className="container mx-auto flex flex-wrap justify-between px-4">
      <div className="w-full sm:w-1/2 lg:w-1/4 mb-4">
        <h4 className="font-bold mb-4">Quick Links</h4>
        <ul className="space-y-2">
          <li><a href="/mcq" className="hover:text-indigo-200">MCQs</a></li>
          <li><a href="/tutorial" className="hover:text-indigo-200">Tutorials</a></li>
          <li><a href="/" className="hover:text-indigo-200">Tech News</a></li>
          <li><a href="/" className="hover:text-indigo-200">Other Blogs</a></li>
        </ul>
      </div>
      <div className="w-full sm:w-1/2 lg:w-1/4 mb-4">
        <h4 className="font-bold mb-4">Connect With Us</h4>
        <div className="flex space-x-4">
          <a href="/" className="hover:text-indigo-200"><Twitter /></a>
          <a href="/" className="hover:text-indigo-200"><Linkedin /></a>
          <a href="/" className="hover:text-indigo-200 font-bold">GitHub</a>
        </div>
      </div>
      <div className="w-full sm:w-1/2 lg:w-1/4 mb-4">
        <h4 className="font-bold mb-4">About NerdNate</h4>
        <p className="max-w-xs">Empowering minds with tech education and resources.</p>
        <a href="/underdev" className="text-indigo-200 hover:underline mt-2 inline-block">Learn More</a>
      </div>
    </div>
  </footer>
);

export default Footer;