import React from 'react';
import { Link } from 'react-router-dom';

const BlogCard = ({ title, excerpt, date, slug }) => {
  return (
    <div className="w-full bg-indigo-50 shadow-md hover:shadow-lg transition-shadow duration-300 border border-indigo-200 rounded-lg p-4">
      <div className="mb-2">
        <h2 className="text-lg sm:text-xl font-bold text-indigo-800">{title}</h2>
        <p className="text-xs sm:text-sm text-indigo-600">{date}</p>
      </div>
      <div>
        <p className="text-sm sm:text-base text-indigo-700">{excerpt}</p>
        <Link to={`/${slug}`}>
          <button className="mt-4 bg-indigo-600 text-white py-2 px-4 rounded hover:bg-indigo-700 transition-colors duration-300">
            Read More
          </button>
        </Link>
      </div>
    </div>
  );
};

export default BlogCard;
