const tutorials=[
    {
      "id": 1,
      "tag": "Java",
      "title": "Introduction to Java",
      "description": "Learn Java basics, including syntax, data types, and OOP concepts. Set up Java and write your first program.",
      "imageUrl": require("../images/java-logo.jpg")
    },
    {
      "id": 2,
      "tag": "DSA",
      "title": "Data Structures",
      "description": "Understand the essentials of Data Structures for effective data management and manipulation.",
      "imageUrl": require("../images/dsa.jpg")
    },
    {
      "id": 3,
      "tag": "C++",
      "title": "C++ Basics: Getting Started",
      "description": "Cover C++ fundamentals like variables, control structures, and functions. Write simple programs and learn OOP.",
      "imageUrl": require("../images/cpp-logo.png")
    },
    {
      "id": 4,
      "tag": "C++",
      "title": "C++ Pointers and Memory Management",
      "description": "Explore pointers and memory management in C++, including stack vs. heap and dynamic memory management.",
      "imageUrl": require('../images/cpp-logo2.png')
    },
    {
      "id": 5,
      "tag": "Python",
      "title": "Python for Beginners",
      "description": "Start with Python basics, covering variables, loops, conditionals, and functions for clean coding.",
      "imageUrl": require('../images/python.jpg')
    },
    {
      "id": 6,
      "tag": "Python",
      "title": "Python for Data Science",
      "description": "Learn Python for data science using libraries like NumPy and pandas, focusing on data manipulation and visualization.",
      "imageUrl": require("../images/data-science.png")
    }
  ]
  
export default tutorials;