import { Link } from "react-router-dom";

const TutCard = ({ title, description, imageUrl }) => {
  return (
    <div className="bg-white rounded-lg overflow-hidden shadow-lg transition-transform duration-300 hover:scale-105 mx-auto max-w-xs flex flex-col">
      <img className="w-full h-36 sm:h-40 object-cover" src={imageUrl} alt={title} />
      <div className="p-4 bg-gradient-to-br from-indigo-100 to-purple-100 flex-grow flex flex-col justify-between">
        <h2 className="font-bold text-lg mb-2 text-indigo-800">{title}</h2>
        <p className="text-indigo-600 text-sm md:text-base">{description}</p>
        <Link to="/tutorial">
          <button className="mt-4 bg-indigo-600 text-white py-2 px-4 rounded hover:bg-indigo-700 transition-colors duration-300">
            Explore
          </button>
        </Link>
      </div>
    </div>
  );
};

export default TutCard;
