import React from 'react';
import { Mail } from 'lucide-react';
import { Link } from 'react-router-dom';

const UnderDevelopment = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-indigo-500 to-purple-600 text-white">
      <div className="text-center p-8">
        {/* Message */}
        <h1 className="text-4xl sm:text-5xl font-bold mb-6">We’re Under Development!</h1>
        <p className="text-lg sm:text-xl mb-8">
          Sorry for the inconvenience. We’re currently working hard to bring you an amazing experience.
        </p>

        {/* Contribute Message */}
        <p className="mb-4 text-indigo-200">
          Want to contribute? We’d love your help! Reach out to us via email.
        </p>

        {/* Email Button */}
        <a
          href="mailto:hopes.alive2004@gmail.com" // Replace this with your actual contact email
          className="bg-white text-indigo-600 px-6 py-3 rounded-full font-bold hover:bg-indigo-100 transition-colors duration-300 inline-flex items-center"
        >
          <Mail className="mr-2" />
          Contact Us
        </a>

      
        <div className="mt-8">
          <Link to="/">
            <button className="bg-transparent border-2 border-white px-6 py-3 rounded-full font-bold hover:bg-white hover:text-indigo-600 transition-colors duration-300">
              Go Back to Homepage
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default UnderDevelopment;
