import React from 'react';
import { Link } from 'react-router-dom';
import TutCard from './Components/TutCard'
import tutorials from './backend/tutorials';

const Hero = () => (
  <section className="bg-gradient-to-r from-indigo-500 to-purple-600 text-white py-12 sm:py-20">
    <div className="container mx-auto text-center px-4">
      <h1 className="text-4xl sm:text-5xl font-bold mb-6">Master Data Structures,Programming with Interactive Tutorials</h1>
      <p className="text-lg sm:text-xl mb-8">Your go-to platform for CSE resources, from coding tutorials to the latest tech trends.</p>
      <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 justify-center">
        <Link to='/tutorial'>
          <button className="bg-white text-indigo-600 px-8 py-3 rounded-full font-bold hover:bg-indigo-100 transition-colors duration-300 shadow-lg w-full sm:w-auto">
            Start Learning
          </button>
        </Link>
        <Link to='/mcq'>
          <button className="bg-transparent border-2 border-white px-8 py-3 rounded-full font-bold hover:bg-white hover:text-indigo-600 transition-colors duration-300 w-full sm:w-auto">
            Test Your Knowledge
          </button>
        </Link>
      </div>
    </div>
  </section>
);

const Tutorials = () => (
  <div className="container mx-auto px-4 py-8">
    <h1 className="text-3xl font-bold text-center mb-8 text-indigo-800">Programming Tutorials</h1>
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
      {tutorials.map((tutorial) => (
        <TutCard key={tutorial.id} {...tutorial} />
      ))}
    </div>
  </div>
);



const Newsletter = () => (
  <div className="bg-gradient-to-r from-indigo-500 to-purple-600 text-white p-6 rounded-lg shadow-md mt-8">
    <h3 className="text-xl font-bold mb-4">Stay Updated</h3>
    <p className="mb-4">Subscribe to our newsletter for the latest updates, tutorials, and news.</p>
    <form className="flex flex-col sm:flex-row space-y-4 sm:space-y-0">
      <input
        type="email"
        placeholder="Enter your email"
        className="flex-grow p-3 rounded-lg text-indigo-800 focus:outline-none focus:ring-2 focus:ring-purple-400 shadow-sm"
      />
      <Link to='/underdev'>
        <button className="bg-white text-indigo-600 px-6 py-3 rounded-lg font-bold hover:bg-indigo-100 transition-colors duration-300 shadow-lg">
          Subscribe
        </button>
      </Link>
    </form>
  </div>
);

const HomePage = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      <Hero />
      <main className="container mx-auto py-8 px-4">
          <Tutorials />
        <Newsletter />
      </main>
    </div>
  );
};

export default HomePage;
