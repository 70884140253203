import React, { useState } from "react";
import mcqs from './backend/mcqs.json';

const Mcq = () => {
  const [visibleAnswer, setVisibleAnswer] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [pageNumber, setPageNumber] = useState(1);

  const itemsPerPage = 3;
  const totalPages = Math.ceil(mcqs.length / itemsPerPage);

  const idx1=(pageNumber-1)*itemsPerPage;
  const idx2=idx1+itemsPerPage;

  const currentQuestions = mcqs.slice(idx1, idx2);

  const handleOptionClick = (questionIndex, selectedOption, correctAnswer) => {
    setSelectedOptions({
      ...selectedOptions,
      [questionIndex]: {
        selectedOption,
        isCorrect: selectedOption === correctAnswer,
      },
    });
  };

  const toggleAnswer = (index) => {
    setVisibleAnswer(visibleAnswer === index ? null : index);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-indigo-50 to-purple-100 py-8 px-4 sm:px-8">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl font-bold text-center text-indigo-800 mb-6">
          Must Try Aptitude MCQ
        </h1>

        {currentQuestions.map((mcq, index) => {
          const questionIndex = idx1 + index;
          const userAnswer = selectedOptions[questionIndex];

          return (
            <div className="bg-white shadow-md rounded-lg mb-6 p-6 border border-indigo-200" key={index}>
              <p className="text-lg font-semibold text-indigo-900">
                {questionIndex + 1}. {mcq.question}
              </p>
              <ul className="mt-2">
                {mcq.options.map((option, i) => {
                  const isSelected = userAnswer?.selectedOption === option;
                  const isCorrect = userAnswer?.isCorrect;

                  return (
                    <li
                      key={i}
                      onClick={() => !userAnswer && handleOptionClick(questionIndex, option, mcq.answer)}
                      className={`flex items-center mt-1 p-2 rounded-lg cursor-pointer
                        ${userAnswer ? (isSelected ? (isCorrect ? "bg-green-200" : "bg-red-200") : "bg-gray-100") : "bg-indigo-100 hover:bg-indigo-200 transition duration-200"}
                      `}
                    >
                      <span className="mr-2 font-bold text-indigo-700">{String.fromCharCode(65 + i)}.</span>
                      <span className="text-indigo-700">{option}</span>
                    </li>
                  );
                })}
              </ul>

              <button
                className="mt-4 text-indigo-600 font-semibold hover:text-purple-700 transition duration-200"
                onClick={() => toggleAnswer(questionIndex)}
              >
                {visibleAnswer === questionIndex ? "Hide Solution" : "Show Solution"}
              </button>

              {visibleAnswer === questionIndex && (
                <div className="mt-4 p-4 bg-indigo-50 border border-indigo-200 rounded-lg">
                  <p className="font-semibold text-indigo-900">
                    <strong>Solution:</strong>
                  </p>
                  <ul className="list-disc ml-6 mt-2 text-indigo-700">
                    {mcq.solution.map((step, i) => (
                      <li key={i}>{step}</li>
                    ))}
                  </ul>
                  <p className="mt-4 font-semibold text-indigo-900">
                    <strong>Answer:</strong> {mcq.answer}
                  </p>
                </div>
              )}
            </div>
          );
        })}

        <div className="flex justify-between items-center mt-8">
          <button
            className={`px-4 py-2 rounded-lg font-semibold text-white ${pageNumber === 1 ? "bg-indigo-300" : "bg-indigo-500 hover:bg-indigo-600"} transition duration-200`}
            onClick={() => setPageNumber(pageNumber - 1)}
            disabled={pageNumber === 1}
          >
            Previous
          </button>
          <div className="flex space-x-2">
            {[...Array(totalPages)].map((_, idx) => (
              <button
                key={idx}
                className={`px-4 py-2 rounded-lg font-semibold ${pageNumber === idx + 1 ? "bg-purple-600 text-white" : "bg-indigo-200 text-indigo-800 hover:bg-indigo-300"} transition duration-200`}
                onClick={() => setPageNumber(idx + 1)}
              >
                {idx + 1}
              </button>
            ))}
          </div>
          <button
            className={`px-4 py-2 rounded-lg font-semibold text-white ${pageNumber === totalPages ? "bg-indigo-300" : "bg-indigo-500 hover:bg-indigo-600"} transition duration-200`}
            onClick={() => setPageNumber(pageNumber + 1)}
            disabled={pageNumber === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Mcq;
