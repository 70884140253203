import React, { useState } from "react";
import { Menu } from "lucide-react";
import { Link } from "react-router-dom"; // Import the Link component
import logo from "../images/logo.png"; // Ensure this is a transparent PNG

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="bg-gradient-to-r from-indigo-600 to-purple-600 text-white p-4 shadow-md">
      <div className="container mx-auto flex flex-wrap justify-between items-center">
        <div className="flex items-center">
          <Link to="/">
            <img
              src={logo}
              alt="NerdNate Logo"
              className="mr-2 w-10 h-10 cursor-pointer" // Resize the logo to a width and height of 10 (adjust as needed)
              style={{ backgroundColor: "transparent" }} // Ensure no background color is applied
            />
          </Link>
          <span className="text-xl font-bold">NerdNate</span>
        </div>

        <button
          className="lg:hidden"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <Menu />
        </button>
        {/* Navigation Menu */}
        <nav
          className={`${
            isMenuOpen ? "block" : "hidden"
          } w-full lg:w-auto lg:flex lg:items-center mt-4 lg:mt-0`}
        >
          <ul className="flex flex-col lg:flex-row lg:space-x-4 space-y-2 lg:space-y-0">
            <li>
              <Link to="/mcq" className="hover:text-indigo-200 block">
                MCQs
              </Link>
            </li>
            <li>
              <Link to="/tutorial" className="hover:text-indigo-200 block">
                Tutorials
              </Link>
            </li>
            <li>
              <Link to="/underdev" className="hover:text-indigo-200 block">
                Tech News
              </Link>
            </li>
            <li>
              <Link to="/underdev" className="hover:text-indigo-200 block">
                Other Blogs
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
