import React from 'react';
import { useParams } from 'react-router-dom';
import { Clock, User, Tag } from 'lucide-react';
import blogs from '../backend/blogs.json';

const BlogPost = () => {
  const { slug } = useParams();
  const blog = blogs.find((b) => b.slug === slug);

  if (!blog) {
    return (
      <div className="container mx-auto px-4 py-16 text-center">
        <h1 className="text-3xl md:text-4xl font-bold text-gray-800 mb-4">Blog Not Found</h1>
        <p className="text-lg md:text-xl text-gray-600">The blog post you're looking for doesn't exist.</p>
      </div>
    );
  }

  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="container mx-auto px-4 py-8 md:py-12">
        <article className="bg-white shadow-lg rounded-lg overflow-hidden max-w-3xl mx-auto">
          <div className="p-4 md:p-8">
            <h1 className="text-2xl md:text-4xl font-bold text-gray-900 mb-4">{blog.title}</h1>
            <div className="flex flex-wrap items-center text-gray-600 mb-6">
              <div className="flex items-center mr-4 mb-2">
                <User size={16} className="mr-2" />
                <span>{blog.author || 'Anonymous'}</span>
              </div>
              <div className="flex items-center mb-2">
                <Clock size={16} className="mr-2" />
                <span>{blog.date}</span>
              </div>
            </div>
            <div className="prose max-w-none text-gray-800">
              {blog.content.split('\n').map((paragraph, index) => (
                <p key={index} className="mb-4 text-sm md:text-base">{paragraph}</p>
              ))}
            </div>
            {blog.tags && (
              <div className="mt-6 md:mt-8 flex flex-wrap">
                {blog.tags.map((tag, index) => (
                  <span key={index} className="flex items-center bg-indigo-100 text-indigo-800 rounded-full px-2 py-1 text-xs md:text-sm font-semibold mr-2 mb-2">
                    <Tag size={12} className="mr-1" />
                    {tag}
                  </span>
                ))}
              </div>
            )}
          </div>
        </article>
      </div>
    </div>
  );
};

export default BlogPost;