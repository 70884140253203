import React from 'react';
import blogs from './backend/blogs.json';
import BlogCard from './Components/BlogCard';

const Tutorials = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-center mb-8 text-indigo-800">Latest Blogs</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        {blogs.map((blog) => (
          <BlogCard
            key={blog.id}
            title={blog.title}
            excerpt={blog.excerpt}
            slug={blog.slug}
          />
        ))}
      </div>
    </div>
  );
};

export default Tutorials;
